<template>
  <figure
    :class="[$style.root]"
    v-if="withFigureTag"
  >
    <img
      v-if="useSrcSet && srcset"
      :class="[$style.image, classList]"
      data-sizes="auto"
      :data-src="placeholder"
      :data-srcset="srcset"
      :style="`--aspect-ratio: ${aspectRatio};`"
      :alt="image?.alt || image?.filename"
      :data-orientation="orientation"
    >
    <img
      v-else-if="src"
      :class="[$style.image, classList]"
      :data-src="src"
      :style="`--aspect-ratio: ${aspectRatio};`"
      :alt="image?.alt || image?.filename"
      :data-orientation="orientation"
    >
  </figure>

  <img
    v-else-if="useSrcSet && srcset"
    :class="[$style.image, classList]"
    data-sizes="auto"
    :data-src="placeholder"
    :data-srcset="srcset"
    :style="`--aspect-ratio: ${aspectRatio};`"
    :alt="image?.alt || image?.filename"
    :data-orientation="orientation"
  >
  <img
    v-else-if="src"
    :class="[$style.image, classList]"
    :data-src="src"
    :style="`--aspect-ratio: ${aspectRatio};`"
    :alt="image?.alt || image?.filename"
    :data-orientation="orientation"
  >
</template>

<script setup lang=ts>
import 'lazysizes'

const props = defineProps({
  image: Object,
  withFigureTag: {
    type: Boolean,
    default: true
  }
})

const lazyLoad = ref(false)

const aspectRatio = ref(1)

const src = getAbsoluteFileUrl(props.image?.url, props.image?.provider)

const srcset = Object.values(props.image?.formats || {})?.sort((a, b) => a?.width - b?.width)?.map((format: any) => `${getAbsoluteFileUrl(format?.url, props?.image?.provider)} ${format?.width}w`).join(', ')

const useSrcSet = computed(() => {
  if (props?.image?.formats) {
    return Object.keys(props?.image?.formats)?.includes('large')
  } else {
    return false
  }
})

const classList = computed(() => [
  lazyLoad.value ? 'lazyload' : ''
])

const placeholder = 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3508 2480"%3E%3C/svg%3E'

const orientation = computed(() => {
  if (props?.image?.width > props?.image?.height) {
    return 'landscape'
  } else if (props?.image?.width < props?.image?.height) {
    return 'portrait'
  } else {
    return 'square'
  }
})

const setAspectRatio = () => {
  let ratio = null
  if (props?.image?.ratio) {
    ratio = props?.image.ratio
  } else {
    ratio = props?.image?.width / props?.image?.height || 1
  }

  return parseFloat(ratio).toFixed(2)
}

onMounted(() => {
  lazyLoad.value = true
  aspectRatio.value = setAspectRatio()
})
</script>

<style module>
.root {
  backdrop-filter: var(--backdrop-filter--dark);
  margin: 0;
  padding: 0;
}

.image {
  width: var(--base--image--width, 100%);
  height: var(--base--image--height, auto);
  max-height: var(--base--image--max-height);

  user-select: none;

  object-fit: var(--base--image--object-fit, contain);
  object-position: var(--base--image--object-position, center);

  --max-aspect-ratio: max(var(--aspect-ratio), var(--base--image--max-aspect-ratio, 0));
  aspect-ratio: var(--base--image--aspect-ratio, var(--max-aspect-ratio));

  display: block;
  visibility: hidden;
}

.image:global(.lazyloaded) {
  visibility: visible;
}
</style>
